import { graphql } from 'gatsby'
import React from 'react'
import Banner from 'components/Banner'
import Breadcrumb from 'components/Breadcrumb'
import Body from 'components/Body'
import Footer from 'components/Footer'
import { tr } from 'js/translations.js'
import { useLocalization } from 'gatsby-theme-i18n'
import SEO from 'components/SEO'

const Page = ({ data }) => {
  const { locale } = useLocalization()
  const page = data[`page_${locale}`]

  const breadcrumb = [
    {
      text: tr('HOME', locale),
      link: '/',
    },
    {
      text: page.title,
    },
  ]

  return (
    <div className='page' id='page-contact'>
      <div>
        <SEO page={page} />
        <Banner content={page.banner} />
        <Breadcrumb items={breadcrumb} />

        <Body body={page.body} />

        <Footer showContact={false} />
      </div>
    </div>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    page_en: contentfulPage(
      slug: { eq: "contact" }
      node_locale: { eq: "en-US" }
    ) {
      id
      title
      seo {
        title
        description
      }
      banner {
        pretitle
        title
        subtitle
      }
      body {
        ... on ContentfulBlockIntro {
          __typename
          id
          image {
            ...GatsbyImage
          }
          caption
          title
          body {
            raw
          }
        }
        ... on ContentfulBlockIntroExtended {
          __typename
          imageText1 {
            image {
              ...GatsbyImage
            }
            title
            body {
              raw
            }
            link
          }
          imageText2 {
            image {
              ...GatsbyImage
            }
            title
            body {
              raw
            }
            link
          }
          image {
            image {
              ...GatsbyImage
            }
            caption
          }
        }
        ... on ContentfulBlockCenterText {
          __typename
          id
          title
          body {
            raw
          }
          isLeftAligned
        }
        ... on ContentfulBlockImageText {
          __typename
          id
          image {
            ...GatsbyImage
          }
          caption
          title
          body {
            raw
          }
        }
        ... on ContentfulBlockContact {
          __typename
          phone
          email
          address {
            raw
          }
          googleMapLink
          baiduMapLink
          image {
            ...GatsbyImage
          }
        }
      }
    }
    page_zh: contentfulPage(
      slug: { eq: "contact" }
      node_locale: { eq: "zh-CN" }
    ) {
      id
      title
      seo {
        title
        description
      }
      banner {
        pretitle
        title
        subtitle
      }
      body {
        ... on ContentfulBlockIntro {
          __typename
          id
          image {
            ...GatsbyImage
          }
          caption
          title
          body {
            raw
          }
        }
        ... on ContentfulBlockIntroExtended {
          __typename
          imageText1 {
            image {
              ...GatsbyImage
            }
            title
            body {
              raw
            }
            link
          }
          imageText2 {
            image {
              ...GatsbyImage
            }
            title
            body {
              raw
            }
            link
          }
          image {
            image {
              ...GatsbyImage
            }
            caption
          }
        }
        ... on ContentfulBlockCenterText {
          __typename
          id
          title
          body {
            raw
          }
          isLeftAligned
        }
        ... on ContentfulBlockImageText {
          __typename
          id
          image {
            ...GatsbyImage
          }
          caption
          title
          body {
            raw
          }
        }
        ... on ContentfulBlockContact {
          __typename
          phone
          email
          address {
            raw
          }
          googleMapLink
          baiduMapLink
          image {
            ...GatsbyImage
          }
        }
      }
    }
  }
`
